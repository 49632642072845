export default {
    isAuthenticated: function () {
        let user = JSON.parse(sessionStorage.getItem("user") || "{}")
        user = Object.values(user)
        return user.length > 0;
    },
    getUser: function () {
        let user = JSON.parse(sessionStorage.getItem("user") || "{}")
        return user;
    }
}