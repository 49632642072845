<template>
  <div class="row">
    <div class="tables">
      <h2 class="title1">{{ page }}</h2>
      <div class="panel-body widget-shadow">
        <div class="row text-right" v-if="tshow_add_new">
          <router-link :to="tshow_add_new" class="btn btn-success">
            Add New Merchant
          </router-link>
        </div>
        <div class="container" v-if="download_reports_flag">
          <div class="col-md-4 pull-right">
            <span><b>Filter Transactional Dates</b></span>
            <div class="input-group input-daterange">
              <input
                type="text"
                id="mindate"
                class="form-control"
                @change="changeDate('From', $event.target.value)"
                placeholder="From:"
              />

              <div class="input-group-addon">to</div>

              <input
                type="text"
                id="maxdate"
                class="form-control"
                @change="changeDate('To', $event.target.value)"
                placeholder="To:"
              />
            </div>
            <button
              class="btn btn-sm btn-primary dateFilterApplyButton"
              @click="ApplyDate(fDate, tDate, items)"
            >
              Apply
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table" :id="datatable_id">
            <thead>
              <tr>
                <th>#</th>
                <th v-for="(header, index) in theaders" :key="index + 100">
                  {{ header.text }}
                </th>
                <th v-if="taction">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in titems" :key="index + 1000">
                <td>{{ index + 1 }}</td>
                <td v-for="key in theaders" :key="key.name">
                  <span
                    style="color: #337ab7; cursor: pointer"
                    v-if="tview_on_column == key.value"
                    @click.prevent="actionItem('view', item, index)"
                    >{{ item[key.value] }}</span
                  >
                  <span v-else>{{ item[key.value] }}</span>
                </td>
                <td v-if="taction" data-sort="false">
                  <div v-if="!toverwrite_default_action">
                    <button
                      class="btn btn-sm btn-success"
                      style="margin-right: 5px"
                      @click="actionItem('edit', item, index)"
                      title="Edit"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button
                      v-if="tview_on_column == 'action'"
                      class="btn btn-sm btn-info"
                      style="margin-right: 5px"
                      @click="actionItem('view', item, index)"
                      title="View"
                    >
                      <i class="fa fa-eye"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      @click="actionItem('delete', item, index)"
                      title="Delete"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                  <slot name="action" :item="item" :index="index"></slot>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";

export default {
  props: [
    "page_title",
    "headers",
    "items",
    "buttons",
    "page_length",
    "tableaction",
    "show_add_new",
    "view_on_column",
    "overwrite_default_action",
    "mindate",
    "maxdate",
    "download_reports_flag"
  ],
  data() {
    return {
      datatable_id: "random_" + Math.floor(Math.random() * 10000) * 1000,
      page: this.page_title,
      theaders: this.headers,
      titems: this.items,
      show_buttons: this.buttons,
      plength: this.page_length || 1000,
      taction: this.tableaction,
      tshow_add_new: this.show_add_new,
      tview_on_column: this.view_on_column,
      toverwrite_default_action: this.overwrite_default_action,
      fDate: undefined,
      tDate: undefined,
      tdownload_reports_flag: this.download_reports_flag
    };
  },
  methods: {
    getValue(item, key) {
      if (key == this.tview_on_column) {
        return `<a href="#" @click.prevent="actionItem('view',item,index)">${item[key]}</a>`;
      } else {
        return item[key];
      }
    },
    actionItem(name, item, index) {
      this.$emit(name, item, index);
    },
    changeDate(type, value) {
      if (type == "From") {
        this.fDate = new Date(value).getTime();
      }
      if (type == "To") {
        this.tDate = new Date(value).getTime();
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    ApplyDate(fDate, tDate, items) {
      let fromDate = fDate;
      let toDate = tDate;
      if (!isNaN(fromDate) && !isNaN(toDate)) {
        if (fromDate < toDate) {
          let sampleVal = JSON.parse(JSON.stringify(items));
          let tempAray = [];
          sampleVal.map((x) => {
            x.trxDate = new Date(x.trxDate);
          });
          sampleVal.forEach((element) => {
            if (
              element.trxDate.getTime() >= fromDate &&
              element.trxDate.getTime() <= toDate
            ) {
              tempAray.push(element);
            }
          });
          tempAray.map((x) => {
            x.trxDate = this.formatDate(x.trxDate);
          });
          this.titems = tempAray;
        } else {
          alert("To date should be greater than from date.");
        }
      } else {
        if (isNaN(fromDate) && !isNaN(toDate)) {
          alert("From date is wrong or missing.");
        } else if (!isNaN(fromDate) && isNaN(toDate)) {
          alert("To date is wrong or missing.");
        } else {
          alert("Please select date.");
          this.titems = items;
        }
      }
    },
  },
  mounted() {
    let inter = setInterval(() => {
      let trcount = $("#" + this.datatable_id)
        .find("tbody")
        .find("tr").length;
      let datacount = this.items.length;
      if (this.titems.length > 0 && trcount == datacount) {
        let options = {
          pagingType: "full_numbers",
          pageLength: this.plength,
        };
        if (this.show_buttons) {
          options.dom = "Bfrtip";
          options.buttons = ["copy", "csv", "print"];
        }
        $("#" + this.datatable_id).DataTable(options);
        clearInterval(inter);
      }
    }, 100);
  },
};
</script>

<style scoped>
.dateFilterApplyButton {
  float: right;
  font-size: 14px;
  margin-right: -16%;
  margin-top: -10%;
  padding: 2.1%;
}
</style>