<template>
<div class="row">
  <my-table v-if="transactions"
            :tableaction="false"
            page_title="Download Reports"
            :headers="headers"
            :items="transactions"
            :buttons="true"
            :overwrite_default_action="true"
            @reverse="reverseTransaction"
            >
  </my-table>
</div>
</template>

<script>
import db from "@/db";
import config from "@/config";
export default {
  data(){
    return {
      headers:[
        {text:"Amount",value:"amount"},
        {text:"Transaction ID",value:"trxId"},
        {text:"Transaction Date",value:"trxDate"},
        {text:"Reversal Status",value:"status"},
      ],
      transactions:[]
    }
  },
  beforeMount() {
    let user = config.getUser();
    let device_ides=user.deviceIds.map(t=>t.device_id);
    console.log(device_ides)
    db.collection("Transaction")
        /*.where("device_id","in",device_ides)*/
        .where("status","in",["reverse","reverse_done"])
        .where("email","==",user.email)
        .get().then((trasnap)=>{
      trasnap.forEach((trx)=>{
          let trxdata = trx.data()
          trxdata.id = trx.id;
        trxdata.status = trxdata.status=="reverse"?"Pending":"Reverse Done";
          this.transactions.push(trxdata);
      })
    })
  },
  methods:{
    reverseTransaction(trx,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reverse it!'
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("Transaction").doc(trx.id).update({"status":"reverse"}).then(()=>{
            this.$swal.fire('Transaction reverse request has been submitted.', '', 'success');
            this.transactions.splice(index,1);
          })
        }
      });
    }
  }
}
</script>