// src/firebaseConfig.js
import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAn5ocZMIT-Ypz5lNAlZQZ91RJ95_cbMpU",
    authDomain: "iconpay-user.firebaseapp.com",
    databaseURL: "https://iconpay-user.firebaseio.com",
    projectId: "iconpay-user",
    storageBucket: "iconpay-user.appspot.com",
    messagingSenderId: "316133969355",
    appId: "1:316133969355:web:6321249313f4482f84ba4e",
    measurementId: "G-BDVNGDYCDX"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);