<template>
  <div class="row">
    <div id="page-wrapper">
      <div class="main-page login-page ">
        <h2 class="title1">{{ title }}</h2>
        <div class="widget-shadow">
          <div class="login-body">
            <div class="text-danger text-center">{{login_error}}</div>
            <form action="#" method="post" @submit.prevent="checkForm">
              <div class="form-group">
                <label class="text-left">Email</label>
              <input type="text" class="form-control" :class="{ 'has-error': $v.loginForm.username.$error }" v-model="loginForm.username" name="email" placeholder="Enter Your Email">
              <span class="text-danger" v-show="$v.loginForm.username.$error">This field is required.</span>
              </div>
              <div class="form-group">
                <label class="text-left">Password</label>
              <input type="password" name="password" :class="{ 'has-error': $v.loginForm.password.$error }" v-model="loginForm.password" class="form-control" placeholder="Password">
                <span class="text-danger" v-show="$v.loginForm.password.$error">This field is required or at least 4 charector.</span>
              </div>
              <input type="submit" name="Sign In" value="Sign In">
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength } from "vuelidate/lib/validators";
import db from '../db';
export default {
  data(){
    return {
      login_error: "",
      errors:{},
      title:"Icon Pay Merchant Login",
      loginForm:{
        username:'',
        password: ''
      }
    }
  },
  validations: {
    loginForm: {
      username: { required},
      password: { required, min:minLength(4) }
    }
  },
  methods:{
    checkForm() {
      this.$v.loginForm.$touch();
      if (this.$v.loginForm.$error) return;
      let email = this.loginForm.username;
      let password = this.loginForm.password;
      db.collection("merchants")
          .where("email","==",email)
          .where("password","==",password)
          .where("isAdmin","==",false)
          .get()
          .then((querySnapshot) => {
            if(querySnapshot.docs.length == 0){
              this.login_error = "Username and password not match.";
            }else {
              let data = querySnapshot.docs[0].data();
              sessionStorage.setItem("user", JSON.stringify(data));
              this.$router.replace("/dashboard");
            }
          }).catch((error) => {
        console.log("Error getting documents: ", error);
      });
    }
  }
}
</script>