import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/Login";
import Layout from "@/components/Layout";
import Dashboard from "@/views/Dashboard";
import DownloadReport from "@/views/DownloadReport";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/merchant',
    name: 'Admin',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/reports',
        name: 'Report',
        component: DownloadReport
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: '/reverse-transactions',
        name: 'RTransactions',
        component: DownloadReverseReport
      },
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
import config from "../config"
import Profile from "@/views/Profile";
import DownloadReverseReport from "@/views/DownloadReverseReport";
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !config.isAuthenticated()) next({ name: 'Login' })
  else if (to.name == 'Login' && config.isAuthenticated()) next({ name: 'Dashboard' })
  else next()
})

export default router
