<template>
<div class="row">
  <my-table v-if="transactions"
            :tableaction="true"
            page_title="Download Reports"
            :headers="headers"
            :items="transactions"
            :buttons="true"
            :overwrite_default_action="true"
            @reverse="reverseTransaction"
            download_reports_flag="true"
            >
    <template slot="action" slot-scope="{item, index}">
      <button class="btn btn-primary btnsm" @click.prevent="reverseTransaction(item,index)">Reverse</button>
    </template>
  </my-table>
</div>
</template>

<script>
import db from "@/db";
import config from "@/config";
export default {
  data(){
    return {
      headers:[
        {text:"Amount",value:"amount"},
        {text:"Transaction ID",value:"trxId"},
        {text:"Device Name",value:"device_name"},
        {text:"Transaction Date",value:"trxDate"},
        {text:"Transaction Status",value:"trxStatus"},
      ],
      transactions:[]
    }
  },
  beforeMount() {
    let user = config.getUser();
    let device_ides=user.deviceIds.map(t=>t.device_id);
    console.log(device_ides)
    db.collection("Transaction")
        /*.where("device_id","in",device_ides)*/
        /*.where("status","in",["reverse","reverse_done",null])*/
        .where("email","==",user.email)
        .get().then((trasnap)=>{
      trasnap.forEach( async (trx)=>{
        if(!Object.prototype.hasOwnProperty.call(trx.data(),"status")) {
          let trxdata = trx.data()
          let devices = await db.collection("poses").where("code","==",trxdata.device_id).get();
          if(devices.docs.length >0){
            trxdata.device_name = devices.docs[0].data().name;
          }
          // console.log(trxdata.trxDate)
          trxdata.id = trx.id;
          this.transactions.push(trxdata);
        }
      })
    })
  },
  methods:{
    reverseTransaction(trx,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reverse it!'
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("Transaction").doc(trx.id).update({"status":"reverse"}).then(()=>{
            this.$swal.fire('Transaction reverse request has been submitted.', '', 'success');
            this.transactions.splice(index,1);
          })
        }
      });
    }
  }
}
</script>