<template>
  <div class="main-content">
    <sidebar></sidebar>
    <Header></Header>
    <div id="page-wrapper">
      <div class="main-page">
        <router-view></router-view>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../templates/Header.vue'
import Footer from '../templates/Footer.vue'
import Sidebar from '../templates/Sidebar.vue'
export default {
  name: "Admin",
  components: {
    Header,
    Footer,
    Sidebar
  },
  mounted () {
    document.body.classList.add('cbp-spmenu-push')
  },
  destroyed () {
    document.body.classList.remove('cbp-spmenu-push')
  }
}
</script>